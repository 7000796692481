import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { Table, Button, DatePicker, Form, Spin, Select } from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function Attendance({ userData }) {
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateColumns, setDateColumns] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const early = moment()
    .set({
      year: moment().year(),
      month: moment().month(),
      hour: 7,
      minute: 15,
      second: 0,
      millisecond: 0,
    })
    .format("HH:mm");

  const after = moment()
    .set({
      year: moment().year(),
      month: moment().month(),
      hour: 9,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format("HH:mm");

  const onFinish = (values) => {
    setLoading(true);

    let days = [];
    for (
      var m = moment(values.date[0]);
      m.isSameOrBefore(values.date[1]);
      m.add(1, "days")
    ) {
      days.push({
        title: m.format("YYYY.MM.DD"),
        dataIndex: m.format("YYYY.MM.DD"),
        width: 120,
        align: "center",
        render: (text, row, index) =>
          text !== undefined ? text : <span style={{ color: "red" }}>-</span>,
      });
    }

    setDateColumns(days);

    API.get(`attendance/list`, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD"),
        type: "STUDENT",
        firstName: "",
        classOid: values?.class,
      },
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          let content = result.data.content;
          let tableData = [];
          let dateSet = new Set();

          content.forEach((category) => {
            dateSet.add(moment(category.createdAt).format("YYYY.MM.DD"));
          });

          let headerRow = [{ className: "Name" }];
          dateSet.forEach((date) => {
            headerRow.push({ title: date, dataIndex: date });
          });

          tableData.push(headerRow);

          let userGroups = {};
          content.forEach((category) => {
            const formattedDate = moment(category.createdAt).format(
              "YYYY.MM.DD"
            );
            if (!userGroups[category.user.username]) {
              userGroups[category.user.username] = {
                username: category.user.username,
                className:
                  category.user.firstName + " " + category.user.lastName,
              };
            }
            userGroups[category.user.username][formattedDate] =
              category.registeredDate;
          });

          for (const username in userGroups) {
            tableData.push(userGroups[username]);
          }

          setData(tableData);
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const getClasses = () => {
      API.post(
        "class/all",
        {
          key: "",
          value: searchValue,
          operation: "LIKE",
        },
        { params: { page: 0, size: 50 } }
      ).then((result) => {
        if (result.status === 200) {
          setCategories(result.data.content);
        }
      });
    };
    getClasses();
  }, [searchValue]);

  return (
    <>
      <PageTitle title="Ирц" description="Сурагчын ирц" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        onFinish={onFinish}
                        form={form}
                        initialValues={{ type: "STUDENT" }}
                      >
                        <Form.Item name="class" label="Анги">
                          <Select style={{ width: 300 }} defaultValue={null}>
                            <Option value={null}>Бүгд</Option>
                            {categories.map((cat) => (
                              <Option value={cat?.id} key={cat?.classId}>
                                {cat.displayName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="date"
                          label="Өдөр"
                          rules={[
                            {
                              required: true,
                              message: "Өдөр сонгоно уу!",
                            },
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Харах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={[
                          {
                            title: "№",
                            dataIndex: "id",
                            key: "id",
                            fixed: "left",
                            align: "left",
                            render: (text, row, index) => <>{index + 1}</>,
                          },
                          {
                            title: "Нэвтрэх нэр",
                            dataIndex: "username",
                            key: "username",
                            fixed: "left",
                            align: "left",
                          },
                          {
                            title: "Бүтэн нэр",
                            dataIndex: "className",
                            key: "className",
                            align: "left",
                          },
                          ...dateColumns.map((dateColumn) => ({
                            title: dateColumn.title,
                            dataIndex: dateColumn.title,
                            key: dateColumn.title,
                            width: dateColumn.width,
                            align: dateColumn.align,
                            render: (text, row, index) => {
                              if (text === undefined) {
                                return <div style={{ color: "red" }}>-</div>;
                              }
                              const currentTime = moment(text).format("HH:mm");

                              if (
                                moment(currentTime, "HH:mm").isBefore(
                                  moment(early, "HH:mm")
                                )
                              ) {
                                return (
                                  <div style={{ color: "green" }}>
                                    {currentTime}
                                  </div>
                                );
                              } else if (
                                moment(currentTime, "HH:mm").isSameOrAfter(
                                  moment(early, "HH:mm")
                                ) &&
                                moment(currentTime, "HH:mm").isBefore(
                                  moment(after, "HH:mm")
                                )
                              ) {
                                return (
                                  <div style={{ color: "orange" }}>
                                    {currentTime}
                                  </div>
                                );
                              } else {
                                return (
                                  <div style={{ color: "red" }}>
                                    {currentTime}
                                  </div>
                                );
                              }
                            },
                          })),
                        ]}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedAttendance = connect(mapState, actionCreators)(Attendance);
export { connectedAttendance as Attendance };
